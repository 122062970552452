import React from 'react'
import Section from './../Section'
import SectionHeader from './../SectionHeader'
import Clients from './../Clients'
import './styles.scss'

function ClientsSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Clients
          items={[
            // {
            //   name: 'DPC Frontier',
            //   image:
            //     'https://images.squarespace-cdn.com/content/54c15fbce4b06765d7d750d5/1423715830355-SAXQ7E7QVUI6OX5FLW4J/DPC+FRONTIER-logo-white.png?format=1500w&content-type=image%2Fpng',
            //   width: '150px',
            //   style: { filter: 'invert(100%)' },
            //   website: 'https://www.dpcfrontier.com',
            // },
            // {
            //   name: 'Startup Health',
            //   image:
            //     'https://akute-website-static-assets.s3.amazonaws.com/StartUpHealth_primary_V1_600_150.png',
            //   width: '170px',
            //   website: 'https://www.startuphealth.com',
            // },
            // {
            //   name: 'YCombinator',
            //   image:
            //     'https://akute-website-static-assets.s3.amazonaws.com/yc_logo.png',
            //   width: '170px',
            //   website: 'https://www.ycombinator.com',
            // },
            // {
            //   name: 'Hint Health',
            //   image:
            //     'https://akute-website-static-assets.s3.amazonaws.com/Hint-Health-rgb-g-b.png',
            //   width: '180px',
            //   website: 'https://www.hint.com',
            // },
            // {
            //   name: 'DPC Alliance',
            //   image:
            //     'https://images.squarespace-cdn.com/content/59edf91bfe54ef255de493ce/1514855559842-U57AVDLCEIFGA0KHR7F0/DPC+final+WHITE.png?format=1500w&content-type=image%2Fpng',
            //   width: '120px',
            //   style: { filter: 'invert(100%)' },
            //   website: 'https://dpcalliance.org',
            // },
            {
              name: 'Fourth Tree Health',
              image:
                'https://akute-website-static-assets.s3.amazonaws.com/marketing-site/fourth+tree+logo.png',
              width: '120px',
              website: 'https://www.fourthtreehealth.com/',
            },
            {
              name: 'Dr. Cranney Family Medicine',
              image:
                'https://akute-website-static-assets.s3.amazonaws.com/marketing-site/dr-cranney-logo.png',
              width: '120px',
              website: 'https://www.drcranney.com/',
            },
            {
              name: 'Wellspring DPC',
              image:
                'https://akute-website-static-assets.s3.amazonaws.com/marketing-site/wellspring+dpc+logo.png',
              width: '120px',
              website: 'https://www.wellspringdpc.com/',
            },
            {
              name: 'Welcome Health',
              image:
                'https://akute-website-static-assets.s3.amazonaws.com/marketing-site/welcome+health+logo.png',
              width: '120px',
              website: 'https://welcomehealth.net/',
            },
            {
              name: 'Fountain TRT',
              image:
                'https://akute-website-static-assets.s3.amazonaws.com/marketing-site/fountain_trt_logo.png',
              width: '120px',
              website: 'https://fountaintrt.com/',
            },
            {
              name: 'SOS Mobile Medical',
              image:
                'https://akute-website-static-assets.s3.amazonaws.com/marketing-site/sos_logo.jpg',
              width: '120px',
              website: 'https://sosmmc.com/',
            },
            {
              name: 'WeightWatchers x Sequence',
              image:
                'https://akute-website-static-assets.s3.amazonaws.com/ww_sequence_logo.png',
              width: '263px',
              website: 'https://www.joinsequence.com/',
            },
            // {
            //   name: 'Calibrate',
            //   image: 'https://joincalibrate.com/images/logo.svg',
            //   width: '150px',
            //   style: {
            //     // filter: 'invert(100%)',
            //     color: 'black',
            //     fontColor: 'black',
            //   },
            // },
            // {
            //   name: 'SteadyMd',
            //   image:
            //     'https://www.steadymd.com/wp-content/uploads/2018/06/steady-md-logo-white.png',
            //   width: '150px',
            //   style: { filter: 'invert(100%)' },
            // },
          ]}
        />
      </div>
    </Section>
  )
}

export default ClientsSection
